import moment from "moment";
import { Layout, Popover, Modal, Button, Popconfirm, Table, Input, Dropdown, message, Menu, Row, Col, Tag } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAccountsListAsync, deleteAccountAsync, addAccountAsync } from "../../reducers/portal/portalSlice";
import { PlusCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { selectUser } from "../../reducers/account/accountSlice";
import { useTranslation } from "react-i18next";
import AccountModal from "./AccountModal";
import OrderModal from "./OrderModal";
import UsageModal from "./Usage";
import ChargeModal from "./ChargeModal";

import { Account } from "../../types/Account";

const { Content } = Layout;
const { confirm } = Modal;
const { Search } = Input;

const Accounts = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { t } = useTranslation();
  const [usageModal, setUsageModelVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [chargeVisible, setChargeVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 } as any);
  const [searchStr, setsearchStr] = useState("");
  const [record, setRecord] = useState({});

  const setItem = (record: any) => {
    record._now_ = new Date().toString();
    setRecord(record);
    setModalVisible(true);
  };

  const setOrderItem = (record: any) => {
    if (!user.device) {
      return Modal.error({
        title: "Error",
        content: t("work.bindDeviceText"),
      });
    }
    setRecord(record);
    setChargeVisible(true);
  };

  const setOrdersItem = (record: any) => {
    setRecord(record);
    setOrderVisible(true);
  };

  const setUsage = (record: any) => {
    setRecord(record);
    setUsageModelVisible(true);
  };

  useEffect(() => {
    fetchList({ ...pagination });
  }, [chargeVisible]);

  const onSearch = async (value: string) => {
    setsearchStr(value);
    if (!value) {
      value = "NONE_STR";
    }
    fetchList({ ...pagination, searchStr: value });
  };

  const fetchList = async (params: any) => {
    setLoading(true);
    params.searchStr = params.searchStr || searchStr;
    !params.order && delete params.order;
    !params.sort && delete params.sort;
    for (let key in params) {
      if (params[key] === "NONE_STR") {
        params[key] = "";
      }
    }
    const result = await dispatch(getAccountsListAsync(params));
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        setAccounts(data.list);
        setPagination({
          current: params.current,
          pageSize: params.pageSize,
          order: params.order,
          sort: params.sort,
          total: data.total,
        });
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const setExpireDate = (account: any, plan: any): string => {
    let expiredDate = account.expiredDate ? moment(account.expiredDate) : moment();
    if (moment(expiredDate).isBefore(moment())) {
      expiredDate = moment();
    }
    return expiredDate.add(plan.valid_months, "months").add(plan.valid_days, "days").toString();
  };

  const updateItem = async (account: any) => {
    console.log("Admin/Portal/Accounts.tsx updateItem", account);
    const values = {
      id: account._id,
      username: account.username,
      patientId: account.patientId,
      firstName: account.firstName,
      lastName: account.lastName,
      planId: account.plan._id,
      expiredDate: setExpireDate(account, account.plan),
    };
    const result = await dispatch(addAccountAsync(values));
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        message.success("Successful operation");
        data.result && setRecord(data.result);
      } else if (msg) {
        if (code == 4000444) {
          Modal.error({
            title: "Error",
            content: `${t("work.CreateMaxPatientText")}`,
          });
        } else {
          Modal.error({
            title: "Error",
            content: msg,
          });
        }
      }
    }
  };

  const deleteItem = async (id: string) => {
    setLoading(true);
    const result = await dispatch(
      deleteAccountAsync({
        id,
      })
    );
    setLoading(false);
    const { payload } = result;
    if (payload) {
      const { code, data, msg } = payload;
      if (code === 0) {
        fetchList({ ...pagination });
      } else if (msg) {
        message.error(msg);
      }
    }
    if (typeof payload === "string") {
      message.error(payload);
    }
  };

  const handleTableChange = (changePagination: any, filters: any, sorter: any) => {
    changePagination.sort = sorter.field;
    changePagination.order = sorter.order;
    fetchList({ ...changePagination });
  };

  const menus = (record: any) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Button
              style={{
                width: "100px",
              }}
              type="primary"
              onClick={() => setItem(record)}
              key="edit"
            >
              {t("work.Edit")}
            </Button>
          ),
        },
        {
          key: "2",
          label: (
            <Button
              style={{
                width: "100px",
              }}
              type="primary"
              onClick={() => setOrderItem(record)}
              key="charge"
            >
              {t("work.Charge")}
            </Button>
          ),
        },
        {
          key: "3",
          label: (
            <Button
              style={{
                width: "100px",
              }}
              type="primary"
              onClick={() => setOrdersItem(record)}
              key="orders"
            >
              {t("work.Orders")}
            </Button>
          ),
        },
        {
          key: "4",
          label: (
            <Button
              style={{
                width: "100px",
              }}
              danger
              key="3"
              onClick={() => {
                confirm({
                  title: record.username,
                  content: t("work.Are_you_sure_to_delete_this_account"),
                  onOk() {
                    deleteItem(record._id);
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }}
            >
              {t("work.Delete")}
            </Button>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: t("work.PatientName"),
      dataIndex: "fullName",
      sorter: true,
      width: "200px",
      render: (companyId: any, record: any) => (
        <>
          <span>{`${record.firstName || ""} ${record.lastName || ""}`}</span>
        </>
      ),
    },
    {
      title: t("work.DeviceLoginUsername"),
      dataIndex: "username",
      sorter: true,
      width: "250px",
    },
    {
      title: t("work.PatientID"),
      dataIndex: "patientId",
      sorter: true,
      width: "250px",
    },
    {
      title: t("work.Plan_Name"),
      dataIndex: "planName",
      width: "250px",
      render: (planName: string, record: any) => {
        return <>{planName ? planName : record.expiredDate && moment(record.expiredDate).isAfter(moment()) ? "Once-off payment" : ""}</>;
      },
    },
    {
      title: t("work.Auto_renew"),
      dataIndex: "subscriptionStatus",
      width: "200px",
      render: (subscriptionStatus: string) => <>{subscriptionStatus === "active" ? <Tag color="#87d068">Yes</Tag> : <Tag color="lime">No</Tag>}</>,
    },
    {
      title: t("work.Expired_Date"),
      dataIndex: "expiredDate",
      sorter: true,
      width: "200px",
      render: (expiredDate: string, account: any) => (
        <>
          {account.experienceDate ? (
            <Popover title={""} content={`Money Back Gurantee expired date: ${moment(account.experienceDate).format("DD/MM/YYYY")}`}>
              {expiredDate ? <span>{moment(expiredDate).format("DD/MM/YYYY")}</span> : ""}
            </Popover>
          ) : expiredDate ? (
            <span>{moment(expiredDate).format("DD/MM/YYYY")}</span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: t("work.Status"),
      dataIndex: "expiredDate",
      sorter: true,
      width: "100px",
      render: (expiredDate: string) => (
        <>{expiredDate && moment(expiredDate).isAfter(moment()) ? <Tag color="#2db7f5">Active</Tag> : <Tag color="#f50">Expired</Tag>}</>
      ),
    },
    {
      title: t("work.Created_Date"),
      dataIndex: "createdAt",
      sorter: true,
      width: "200px",
      render: (createdAt: string) => <>{createdAt ? <span>{moment(createdAt).format("DD/MM/YYYY")}</span> : ""}</>,
    },
    {
      title: t("work.Actions"),
      key: "actions",
      fixed: "right" as "right",
      render: (text: string, record: any) => (
        <>
          <Dropdown className="portal-account-menu" overlay={menus(record)} placement="bottomRight">
            <a>
              <MenuOutlined />
            </a>
          </Dropdown>
          <div className="portal-account-actions">
            <Button type="primary" size="small" onClick={() => setItem(record)} key="edit">
              {t("work.Edit")}
            </Button>
            {user.company.publicPlans ? (
              <>
                <Button type="primary" size="small" onClick={() => setOrderItem(record)} key="charge">
                  {t("work.Charge")}
                </Button>
                <Button type="primary" size="small" onClick={() => setOrdersItem(record)} key="orders">
                  {t("work.Orders")}
                </Button>
              </>
            ) : null}
            <Button type="primary" size="small" onClick={() => setUsage(record)} key="usage">
              {t("work.Usage")}
            </Button>
            <Popconfirm title={t("work.Are_you_sure_to_delete_this_account")} onConfirm={() => deleteItem(record._id)} okText="Yes" cancelText="No">
              <Button danger size="small" key="3">
                {t("work.Delete")}
              </Button>
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="eyerising-page-header">
        <div className="header-content">
          <Row justify="space-between">
            <Col xs={24} md={10} className="search">
              <Search placeholder={t("work.SearchName")} width={"100%"} onSearch={onSearch} />
            </Col>
            {user.company.publicPlans ? (
              <Button icon={<PlusCircleOutlined />} onClick={() => setItem({})} type="primary">
                {t("work.AddPatient")}
              </Button>
            ) : null}
          </Row>
        </div>
      </div>
      <Content className="eyerising-content">
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          rowKey={(record: any) => record._id}
          dataSource={accounts}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
      <AccountModal
        record={record}
        setRecord={setOrderItem}
        visible={modalVisible}
        setVisible={setModalVisible}
        getList={() => fetchList(pagination)}
      />
      <ChargeModal record={record} updateItem={updateItem} visible={chargeVisible} setVisible={setChargeVisible} />
      <UsageModal
        record={record}
        setRecord={setRecord}
        visible={usageModal}
        setVisible={setUsageModelVisible}
        getList={() => fetchList(pagination)}
      />
      <OrderModal record={record} setRecord={setRecord} visible={orderVisible} setVisible={setOrderVisible} getList={() => fetchList(pagination)} />
    </>
  );
};

export default Accounts;
